@import '../../assets/css/variable';

.container {
  text-align: center;
  width: 330px;

  @media screen and (max-width: 575.98px) {
    max-width: 290px;
  }
}

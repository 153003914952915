@import '../../assets/css/variable';
@import '../../assets/css/mixins';

$height-border: $height-header - $height-block-header;
$height-content: calc(100% - #{$height-border});

.container {
  height: $height-header;
  background: $main-blue;
  box-shadow: $box-shadow-header;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9997;
  transform: none;
  @include transition-time-fastest;

  .blockBg {
    position: absolute;
    left: 0;
    height: $height-block-header;
    width: $width-block-header;
    background: $white;
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: $height-content;

    @media screen and (max-width: 1199.98px) {
      justify-content: flex-start;
    }

    .logoBlock {
      width: 105px;
      height: 30px;
      margin-right: 53px;
      background: url(../../assets/img/logo.svg) no-repeat center center;
      display: inline-block;
      @include transition-time-fastest;

      &:not(.logoDefault) {
        cursor: pointer;

        &:hover {
          opacity: 0.65;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      @media screen and (max-width: 1499.98px) {
        margin-right: 80px;
      }
    }

    .burgerMenuBlock {
      width: 24px;
      height: 30px;
      background: url(../../assets/img/icon/burger_icon.svg) no-repeat center center;
      margin-right: 14px;
      display: inline-block;
      cursor: pointer;
      @include transition-time-fastest;

      &:active {
        transform: scale(0.9);
        opacity: 0.65;
      }
    }

    .mobileMenuList {
      display: none;
      flex-wrap: wrap;
      align-content: center;
      height: 100%;
    }

    .menuList {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
      flex-grow: 1;

      @media screen and (min-width: 1200px) {
        margin-right: 36px;
        background-image: url('../../assets/img/menu_white_bg_xxl.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
      }

      .headerLink {
        font-family: $main-font;
        font-size: 13px;
        line-height: 15px;
        color: $header-text;
        margin-right: 55.5px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        user-select: none;
        @include transition-time;

        @media screen and (max-width: 1499.98px) {
          margin-right: 40px;
        }

        &::before {
          content: '';
          width: 0;
          height: 2px;
          left: auto;
          right: 0;
          top: auto;
          bottom: -2px;
          z-index: 0;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          background: $main-blue;
          @include transition-time;
        }

        &:hover {
          color: $main-blue;

          &::before {
            visibility: visible;
            opacity: 1;
            width: 100%;
            right: auto;
            left: 0;
          }
        }

        &.active {
          color: $main-blue;
        }
      }
    }
  }
}

.menuAlignSpaceMobile {
  @media screen and (max-width: 1199.98px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

.socialWrapper {
  @media screen and (min-width: 1200px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 27px 0 0 -10px;
  padding-top: 27px;
  border-top: 1px solid $light-blue-border;

  .iconBlock {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    color: $main-blue;
    box-shadow: $box-shadow;
    @include transition-time;

    @media (min-width: 576px) and (max-width: 1199.98px) {
      margin-left: 12px;
    }

    &:hover,
    &:active {
      color: $white;
      background-color: $main-blue;
    }

    &:active {
      transform: scale(0.9);
      box-shadow: 0 0 0 2px rgba($main-blue, 0.5);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($main-blue, 0.5);
    }
  }
}

.stateWrapper {
  position: relative;
  display: inline-block;

  @media screen and (max-width: 1199.98px) {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 50px;
  }

  .stateName {
    font-size: 13px;
    line-height: 1.2;
    color: $text;
    position: relative;
    padding-right: 15px;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
      background: url(../../assets/img/icon/state-arrow-up.svg) no-repeat center center;
      width: 12px;
      height: 7px;
      position: absolute;
      top: calc(50% - 3.5px);
      right: 0;
      transform: scaleY(-1);
      @include transition-time-fastest;
    }

    &.dropdownVisible {
      &::after {
        transform: none;
      }
    }
  }

  .stateBody {
    @media screen and (min-width: 1200px) {
      position: absolute;
      top: calc(100% + 17px);
      left: 50%;
      transform: translateX(-50%);
      background: $white;
      border-radius: 20px;
      padding: 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 330px;
      box-shadow: $box-shadow;
    }

    @media screen and (max-width: 1199.98px) {
      display: block;
      margin-top: 15px;
    }

    &::before,
    &::after {
      @media screen and (min-width: 1200px) {
        content: '';
        position: absolute;
        background: $white;
        top: 0;
        left: calc(50% + 43px);
        width: 20px;
        height: 20px;
        box-shadow: -4px -4px 15px rgba($main-blue, 0.2);
        z-index: -1;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    &::before {
      @media screen and (min-width: 1200px) {
        z-index: 1;
        box-shadow: none;
      }
    }
  }

  .stateClose {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    @include transition-time;

    @media screen and (max-width: 1199.98px) {
      display: none;
    }

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 1;
    }

    i {
      height: 30px;
      background-size: cover;
    }
  }

  .stateQuestion {
    color: $text-zagolovki;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 13px;
    display: block;

    @media screen and (max-width: 1199.98px) {
      font-size: 13px;
    }
  }

  .stateBlue {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 13px;
    color: $main-blue;

    @media screen and (max-width: 1199.98px) {
      font-size: 13px;
    }
  }

  .stateButton {
    width: 100%;
    display: flex;
    margin-bottom: 13px;

    > div {
      width: 100%;
      display: block;
    }

    button {
      width: 100%;
    }
  }

  .stateChangeLocation {
    color: $light-text;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1.2;
    cursor: pointer;
    user-select: none;
    display: block;
    @include transition-time;

    &:hover {
      color: $main-blue;
    }

    &:active {
      color: $medium-blue;
    }
  }
}

.closeMobMenu {
  display: none;
}

.menuListWrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .container {
    .blockBg {
      width: 100%;
    }

    .contentContainer {
      .logoBlock {
        margin-right: 0;
      }

      .mobileMenuList {
        display: flex;
      }

      .closeMobMenu {
        background-size: 18px;
        width: 24px;
        height: 30px;
        margin-right: 14px;
        display: inline-block;
        cursor: pointer;
        padding: 0;
        @include transition-time-fastest;

        &:active {
          transform: scale(0.9);
          opacity: 0.65;
        }
      }

      .menuList {
        position: fixed;
        z-index: 9999;
        top: auto;
        bottom: 0;
        left: 0;
        height: calc(100% - #{$height-header});
        width: 100%;
        max-width: 280px;
        overflow: auto;
        background: $white;
        display: block;
        padding: 30px 15px;
        opacity: 0.5;
        transform: translateX(-100%);
        visibility: hidden;
        box-shadow: $box-shadow-mob-menu;
        @include transition-time;

        @media screen and (max-width: 575.98px) {
          max-width: 100%;
        }

        &.openedMenu {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
        }

        .menuListWrapper {
          margin-bottom: 8px;
          position: relative;
          flex-direction: column-reverse;
        }

        .listMenu {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .headerLink {
          margin: 0;
          font-size: 15px;
          line-height: 18px;
          color: $text-zagolovki;

          &:not(:last-of-type) {
            margin: 0 0 43px 0;
          }
        }
      }

      .backdropMenu {
        position: fixed;
        background: rgba($black, 0.3);
        top: auto;
        bottom: 0;
        left: 0;
        height: calc(100% - #{$height-header});
        width: 100%;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        @include transition-time;

        &.visibleBackdrop {
          visibility: visible;
          opacity: 1;
          pointer-events: initial;
          animation: opacityBackdrop 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        @keyframes opacityBackdrop {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
}

@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 101;
  display: flex;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $modal-bg;
    opacity: 1;
    @include transition-time-fastest;
  }

  .modal {
    position: relative;
    padding: 25px;
    background: $white;
    margin: auto;
    z-index: 102;
    box-shadow: 5px 5px 15px rgba(107, 143, 255, 0.2);
    border-radius: 20px;
    transform: none;
    visibility: visible;
    opacity: 1;
    @include transition-time-fastest;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .link-blue {
    font-size: 14px;
  }

  p {
    color: $text-zagolovki;
    font-size: 14px;
    line-height: 1.3;

    @media screen and (max-width: 1499.98px) {
      font-size: 13px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 12px;
    }
  }
}

.dNone {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  .modal {
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
  }

  .overlay {
    opacity: 0;
  }
}

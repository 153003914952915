@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 10000;
  display: flex;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $modal-bg;
    opacity: 1;
    @include transition-time-fastest;
  }

  .modal {
    position: relative;
    padding: 25px;
    background: $white;
    margin: 25px auto;
    z-index: 102;
    box-shadow: 5px 5px 15px rgba(107, 143, 255, 0.2);
    border-radius: 20px;
    transform: none;
    visibility: visible;
    opacity: 1;
    min-width: 330px;
    width: 100%;
    max-width: 80rem;
    @include transition-time-fastest;

    @media screen and (max-width: 1499.98px) {
      max-width: 70rem;
    }

    @media screen and (max-width: 1199.98px) {
      max-width: calc(100% - 2rem);
    }

    @media screen and (max-width: 575.98px) {
      min-width: 300px;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .link-blue {
    font-size: 14px;
  }

  .modalContent {
    max-height: calc(100% - 30px);
    margin: 15px 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.2;
    color: $text-zagolovki;
    margin-bottom: 12px;

    @media screen and (max-width: 1499.98px) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 1199.98px) {
      margin-bottom: 5px;
    }
  }

  b,
  strong {
    color: $text-zagolovki;
  }

  h1 {
    font-size: 36px;

    @media screen and (max-width: 1499.98px) {
      font-size: 28px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 28px;

    @media screen and (max-width: 1499.98px) {
      font-size: 24px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 20px;

    @media screen and (max-width: 1499.98px) {
      font-size: 18px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 16px;
    }
  }

  h4 {
    font-size: 14px;
    margin-bottom: 18px;

    @media screen and (max-width: 1499.98px) {
      font-size: 13px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 12px;
    }
  }

  p {
    color: $text-zagolovki;
  }

  p,
  li {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 17px;

    @media screen and (max-width: 1499.98px) {
      font-size: 13px;
      line-height: 1.35;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 12px;
      line-height: 1.4;
    }
  }

  ul {
    margin-bottom: 24px;

    @media screen and (max-width: 1499.98px) {
      margin-bottom: 15px;
    }

    > p {
      padding-left: 15px;
    }

    p,
    li {
      margin-bottom: 12px;

      @media screen and (max-width: 1499.98px) {
        margin-bottom: 9.5px;
      }

      @media screen and (max-width: 1199.98px) {
        margin-bottom: 8px;
      }
    }

    li {
      position: relative;
      padding-left: 15px;
      font-family: $main-font;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.4;
      color: $text-zagolovki;
      margin-bottom: 18px;

      @media screen and (max-width: 1499.98px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1199.98px) {
        font-size: 12px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        width: 5px;
        height: 5px;
        background: $main-blue;
        border-radius: 50%;

        @media screen and (max-width: 1499.98px) {
          top: 6px;
        }

        @media screen and (max-width: 1199.98px) {
          top: 4px;
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      font-size: 14px;
      line-height: 1.4;
      color: $black;
      margin-bottom: 18px;
      position: relative;
      padding-left: 15px;

      @media screen and (max-width: 1499.98px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1199.98px) {
        font-size: 12px;
      }

      &::before {
        content: counter(my-awesome-counter) '. ';
        color: $main-blue;
        font-weight: bold;
        margin-right: 12px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.dNone {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  .modal {
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
  }

  .overlay {
    opacity: 0;
  }
}

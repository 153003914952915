@import '../../assets/css/variable';

.wrapper {
  min-height: calc(100vh - #{$height-header});
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapperHead {
    flex: 1;
  }
}

.row {
  display: flex;
  position: relative;
  flex-direction: row;
}

@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.checkbox {
  position: relative;
  padding-left: 34px;
  margin-bottom: 10px;
  cursor: pointer;
  height: 24px;
  display: inline-grid;
  align-content: center;
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: $text;

  input {
    position: absolute;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $main-blue;
      border: none;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $forms;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 24px;
      height: 24px;
      background: url(../../assets/img/icon/check_icon.svg) no-repeat center center;
    }
  }
}

.radio {
  position: relative;
  padding-left: 30px;
  margin: 10px;
  cursor: pointer;
  min-height: 25px;
  display: inline-flex;
  align-content: center;
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  color: $radio-color;
  font-size: 14px;
  line-height: 1.1;

  @media screen and (max-width: 1499.98px) {
    font-size: 13px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 12px;
  }

  input {
    position: absolute;
    height: 0;
    width: 0;

    &:checked ~ .checkmark:after {
      background-size: 11px;
      opacity: 1;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border: 1px solid $main-blue;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(../../assets/img/icon/radio-checked.svg) no-repeat center center;
      background-size: 0;
      opacity: 0;
      @include transition-time-fastest;
    }
  }
}

.container {
  margin-bottom: 10px;
  position: relative;

  .label {
    text-align: left;
    display: block;
    margin-left: 15px;
    margin-bottom: 5px;
    font-family: $main-font;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: $text;

    span {
      color: $main-blue;
    }
  }

  .inputWrapper {
    position: relative;
    //overflow: hidden;

    .searchIcon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;

      @media screen and (max-width: 1499.98px) {
        width: 35px;
        height: 35px;
      }

      @media screen and (max-width: 1199.98px) {
        height: 30px;
        width: 30px;
      }
    }

    .searchInput {
      padding-left: 37px;

      @media screen and (max-width: 1499.98px) {
        padding-left: 33px;
      }
    }
  }

  .input {
    width: 100%;
    border-radius: 100px;

    input {
      border: 1px solid $forms;
      @include transition-time;
    }

    &:not(.inputError) {
      input {
        &:focus {
          box-shadow: 0 0 0 2px rgba($medium-blue, 0.5);
          border-color: transparent;
        }
      }
    }
    select {
      border-width: 1px;
    }
    input,
    select {
      width: 100%;
      border-radius: 100px;
      padding: 0 15px 0 15px;
      height: 40px;
      font-family: $main-font;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      align-items: center;
      color: $text-zagolovki;

      ::placeholder {
        color: $input;
      }

      @media screen and (max-width: 1499.98px) {
        height: 35px;
      }

      @media screen and (max-width: 1199.98px) {
        font-size: 11px;
        line-height: 13px;
        height: 30px;
      }
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }

  .inputError {
    input {
      border-color: $error;

      &:focus {
        box-shadow: 0 0 0 2px rgba($error, 0.5);
        border-color: transparent;
      }
    }
  }

  .textError {
    text-align: left;
    margin: 5px 15px 0 15px;
    font-family: $main-font;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: $error;

    @media screen and (max-width: 1199.98px) {
      font-size: 10px;
    }
  }

  .inputPassword {
    padding: 0 45px 0 15px;
  }

  .blockLogo {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 8px);
    right: 15px;
  }
}

.switchContainer {
  display: flex;
  align-items: center;

  .labelActive {
    margin-right: 12px;
  }

  .labelInactive {
    margin-left: 12px;
  }

  .isActive {
    color: $text-zagolovki;
  }

  .switch {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    width: 44px;
    height: 24px;
    background-color: $error;
    border-radius: 100px;
    outline: none;
    position: relative;
    transition: 200ms;

    &:checked {
      background-color: $main-green;
    }

    &:before {
      content: '';
      width: 21px;
      height: 21px;
      background-color: #fff;
      position: absolute;
      top: 1px;
      right: 1px;
      border-radius: 50%;
      transition: 200ms;
    }

    &:checked:before {
      right: 22px;
    }
  }
}

.datePicker {
  padding: 0;
}

@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.container {
  .menuList {
    flex-wrap: wrap;
    align-content: center;

    @media screen and (max-width: 575.98px) {
      align-content: center;
      flex: 0 0 100%;
      justify-content: center;
    }

    .linksWrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 991.98px) {
        margin-top: 23px;
        flex: 0 0 100%;
        justify-content: space-between;
      }

      @media screen and (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .linksFooter {
      font-family: $main-font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.3;
      color: $text;
      margin-right: 40.5px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      @include transition-time;

      @media screen and (max-width: 1499.98px) {
        margin-right: 30.5px;
      }

      @media screen and (max-width: 991.98px) {
        margin-right: 5px;
      }

      @media screen and (max-width: 575.98px) {
        margin: 0 0 18px 0;
      }

      &::before {
        content: '';
        width: 0;
        height: 2px;
        left: auto;
        right: 0;
        top: auto;
        bottom: -2px;
        z-index: 0;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        background: $main-blue;
        @include transition-time;
      }

      &:hover {
        color: $main-blue;

        &::before {
          visibility: visible;
          opacity: 1;
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }

  .top {
    display: flex;
  }

  .topContentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21.5px 0;

    @media screen and (max-width: 1499.98px) {
      padding: 21.5px 0 15px;
    }

    @media screen and (max-width: 1199.98px) {
      padding: 15px 0;
    }

    @media screen and (max-width: 991.98px) {
      position: relative;
      display: block;
    }

    .logoBlock {
      width: 159px;
      height: 45px;
      margin-right: 80px;
      background: url(../../assets/img/footer-logo.svg) no-repeat center center;
      background-size: contain;
      display: inline-block;
      @include transition-time-fastest;

      &:not(.logoDefault) {
        cursor: pointer;

        &:hover {
          opacity: 0.65;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      @media screen and (max-width: 1499.98px) {
        width: 130px;
        height: 37px;
        margin-right: 67px;
      }

      @media screen and (max-width: 575.98px) {
        width: 140px;
        height: 40px;
        margin-right: 0;
      }
    }

    .socialWrapper {
      display: flex;
      align-items: center;

      @media (min-width: 576px) and (max-width: 991.98px) {
        position: absolute;
        right: 0;
        top: 20px;
      }

      @media screen and (max-width: 575.98px) {
        flex: 0 0 100%;
        justify-content: center;
        margin: 7px 0 0 -10px;
      }
    }

    .iconBlock {
      width: 36px;
      height: 36px;
      border: 1px $forms solid;
      border-radius: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;
      color: $main-blue;
      @include transition-time;

      @media (min-width: 576px) and (max-width: 1499.98px) {
        width: 30px;
        height: 30px;
      }

      @media (min-width: 576px) and (max-width: 1199.98px) {
        margin-left: 12px;
      }

      &:hover,
      &:active {
        color: $white;
        background-color: $main-blue;
        border-color: $main-blue;
      }

      &:active {
        transform: scale(0.9);
        box-shadow: 0 0 0 2px rgba($main-blue, 0.5);
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba($main-blue, 0.5);
      }
    }
  }

  .bottomContentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(25px / 2) 0;

    @media screen and (max-width: 1499.98px) {
      padding: calc(30px / 2) 0;
    }

    @media screen and (max-width: 575.98px) {
      flex-direction: column;
      padding: calc(35px / 2) 0;
    }

    .bottomPolicy {
      display: flex;

      @media screen and (max-width: 575.98px) {
        flex-direction: column;
        text-align: center;
        margin-bottom: 7px;
      }
    }

    .AllRights {
      font-size: 12px;

      @media screen and (max-width: 575.98px) {
        margin-bottom: 8px;
      }
    }

    .textBlack {
      cursor: pointer;
      color: $black;
      font-size: 12px;
      @include transition-time-fastest;

      @media screen and (min-width: 576px) {
        margin-left: 7px;
      }

      @media screen and (min-width: 1500px) {
        margin-left: 5px;
      }

      &:hover {
        color: $main-blue;
      }
    }

    .developWrapper {
      @media screen and (max-width: 991.98px) {
        display: flex;
        align-items: center;
      }
    }

    .developText {
      @media screen and (max-width: 991.98px) {
        font-size: 12px;
      }
    }

    .logoBlock {
      width: 54px;
      height: 8px;
      background: url(../../assets/img/bonum_logo.svg) no-repeat center center;
      display: inline-block;
      margin-left: 7px;
      cursor: pointer;
      @include transition-time-fastest;

      &:hover {
        opacity: 0.65;
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

@import '../../../assets/css/variable';

.container {
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 26px;
    text-align: center;
    color: $text-zagolovki;
    margin-bottom: 25px;
  }
  .checkbox {
    margin-top: 10px;
  }
  .button {
    margin-top: 10px;
  }
  .text {
    margin-top: 15px;
    span {
      font-family: $main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $header-text;
    }
    .link {
      color: $main-blue;
      cursor: pointer;
    }
  }
}

@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.container {
  padding: 90px 0;
  background: $light-blue-bg;

  @media screen and (max-width: 1499.98px) {
    padding: 40px 0 50px;
  }

  @media screen and (max-width: 1199.98px) {
    padding: 40px 0 75px;
  }

  @media screen and (max-width: 991.98px) {
    padding: 40px 0 55px;
  }

  @media screen and (max-width: 575.98px) {
    padding: 40px 0 80px;
  }

  .locationsName {
    font-family: $main-font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.85;
    color: $text;
    display: block;
    cursor: pointer;
    @include transition-time;

    &:hover {
      color: $text-zagolovki;
    }

    &:active {
      color: $main-blue;
      text-decoration: underline;
    }

    @media screen and (min-width: 1500px) {
      line-height: 2;
    }

    @media screen and (max-width: 1499.98px) {
      font-size: 13px;
    }
  }

  .statesContainer {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1499.98px) {
      justify-content: space-between;
    }

    @media screen and (max-width: 767.98px) {
      //padding-right: 0.75rem;
      //margin-right: -0.75rem
    }

    .item {
      flex: 1;
      min-width: 155px;
      border-left-width: 1px;
      border-left-color: $forms;
      padding: 0 40px 0 40px;

      @media screen and (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 1499.98px) {
        padding: 0 10px 0 45px;
      }

      @media (min-width: 1200px) and (max-width: 1499.98px) {
        min-width: 175px;
        flex: initial;
      }

      @media screen and (max-width: 1199.98px) {
        padding: 0 10px 0 18px;
      }
    }
  }

  .sliderList {
    display: none;
  }

  .divList {
    display: flex;
    margin-top: 47px;

    @media screen and (min-width: 1500px) {
      margin-top: 42px;
    }

    @media screen and (max-width: 1199.98px) {
      margin-top: 18px;
    }

    .item:last-child {
      border-right-width: 1px;
      border-right-color: $forms;
    }
  }
}

@media (max-width: 991.98px) {
  .container {
    .statesContainer {
      .item {
        padding: 0 15px 0 13px;
        min-width: initial;
      }
    }

    .sliderList {
      display: block;
      margin-top: 40px;
      padding-bottom: 7px;

      @media screen and (max-width: 767.98px) {
        margin-top: 30px;
      }
    }

    .divList {
      display: none;
    }
  }
}

@import '../../../assets/css/variable';
@import '../../../assets/css/mixins';

.container {
  display: inline-block;

  button {
    height: 35px;
    padding: 0 50px;
    display: flex;
    align-content: center;
    border-radius: 100px;
    font-family: $main-font;
    font-weight: 500;
    font-size: 14px;
    //line-height: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;
    @include transition-time;

    .left {
      margin-right: 5px;
    }

    .right {
      margin-left: 5px;
    }
  }

  .small {
    height: 30px;
    padding: 0 30px 0 30px;
    font-size: 13px;
  }

  .medium {
    height: 35px;
    padding: 0 45px 0 45px;
  }

  .large {
    height: 40px;
    padding: 0 50px;

    @media screen and (max-width: 1499.98px) {
      height: 35px;
      padding: 0 45px;
      font-size: 13px;
    }

    @media screen and (max-width: 1199.98px) {
      height: 30px;
      padding: 0 30px;
      font-size: 12px;
    }
  }

  .success {
    background: $main-green;

    &:hover {
      background: $medium-green;
    }

    &:active {
      background: darken($medium-green, 5);
      box-shadow: 0 0 0 3px rgba($medium-green, 0.5);
    }

    &:focus {
      background: darken($medium-green, 5);
      box-shadow: 0 0 0 3px rgba($medium-green, 0.5);
    }
  }

  .danger {
    background: $error;

    &:hover {
      background: $medium-error;
    }

    &:active {
      background: darken($medium-error, 5);
      box-shadow: 0 0 0 3px rgba($medium-error, 0.5);
    }

    &:focus {
      background: darken($medium-error, 5);
      box-shadow: 0 0 0 3px rgba($medium-error, 0.5);
    }
  }

  .blue {
    background: $main-blue;

    &:hover {
      background: $medium-blue;
    }

    &:active {
      background: darken($medium-blue, 5);
      box-shadow: 0 0 0 3px rgba($medium-blue, 0.5);
    }

    &:focus {
      background: darken($medium-blue, 5);
      box-shadow: 0 0 0 3px rgba($medium-blue, 0.5);
    }
  }

  .orange {
    background: $rating;

    &:hover {
      background: lighten($rating, 5);
    }

    &:active {
      background: darken($rating, 5);
      box-shadow: 0 0 0 3px rgba($rating, 0.5);
    }

    &:focus {
      background: darken($rating, 5);
      box-shadow: 0 0 0 3px rgba($rating, 0.5);
    }
  }

  .blue-light {
    background: $medium-blue;

    &:hover {
      background: $main-blue;
    }

    &:active {
      background: darken($main-blue, 5);
      box-shadow: 0 0 0 3px rgba($main-blue, 0.5);
    }

    &:focus {
      background: darken($main-blue, 5);
      box-shadow: 0 0 0 3px rgba($main-blue, 0.5);
    }
  }

  .blue-opacity {
    background: $semi-transparent-blue;
    color: $main-blue;
    border: 1px solid $medium-blue;

    &:hover {
      background: darken($semi-transparent-blue, 5);
    }

    &:active {
      background: darken($semi-transparent-blue, 10);
      box-shadow: 0 0 0 2px rgba($medium-blue, 0.5);
    }

    &:focus {
      background: darken($semi-transparent-blue, 5);
      box-shadow: 0 0 0 2px rgba($medium-blue, 0.5);
    }
  }

  .main {
    background: $clinic-bg;

    &:hover {
      background: $medium-clinic-bg;
    }

    &:active {
      background: darken($medium-clinic-bg, 5);
      box-shadow: 0 0 0 3px rgba($medium-clinic-bg, 0.5);
    }

    &:focus {
      background: darken($medium-clinic-bg, 5);
      box-shadow: 0 0 0 3px rgba($medium-clinic-bg, 0.5);
    }
  }

  .transparentWhite {
    border: 1px solid $white;

    &:hover {
      background: $white;
      color: $main-blue;
    }

    &:active {
      background: darken($white, 5);
      color: $main-blue;
      box-shadow: 0 0 0 3px rgba($white, 0.5);
    }

    &:focus {
      background: darken($white, 5);
      color: $main-blue;
      box-shadow: 0 0 0 3px rgba($white, 0.5);
    }
  }

  .transparentBlue {
    border: 1px solid $main-blue;
    color: $main-blue;
  }

  .disabled {
    background: $forms;

    &:hover {
      background: $forms;
      cursor: not-allowed;
    }

    &:active {
      background: darken($forms, 5);
      box-shadow: 0 0 0 3px rgba($forms, 0.5);
    }

    &:focus {
      background: darken($forms, 5);
      box-shadow: 0 0 0 3px rgba($forms, 0.5);
    }
  }
}
